import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TextField, IconButton, Icon} from "@material-ui/core";
import sample from './../../../../images/doc_and_support.svg'
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {graphql, Link, navigate, useStaticQuery} from "gatsby";

const useStyles = makeStyles(({palette, ...theme}) => ({
  introWrapper: {
    padding: "5rem 0rem",
    backgroundImage: 'url("/assets/images/abstract-bg-1.png")',
    backgroundPosition: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  searchBox: {
    padding: "20px",
    borderRadius: "10px"
  },
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "30px",
      borderRadius: "10px",
      boxShadow: '0 10px 35px rgb(0 0 0 / 12%) !important',
      padding: '20px',
      "& button": {
        order: 3,
      },
      "& > div.MuiAutocomplete-endAdornment": {
        position: "relative",
        order: 2,
      },
    },
  },
}));

const OPTIONS_LIMIT = 5;
const filterOptions = createFilterOptions({
  limit: OPTIONS_LIMIT
});

const SearchView = () => {
  const classes = useStyles();
  const {allMdx} = useStaticQuery(
    graphql`
        query search {
            allMdx {
                edges {
                    node {
                        frontmatter {
                            category
                            title
                            tab
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `
  );
  const options = allMdx.edges.map((option) => {
    const firstLetter = option.node.frontmatter.category.toUpperCase();
    return {
      firstLetter: firstLetter,
      ...option,
    };
  });

  const handleSub = (e, ee) => {
    if(ee.node !== null && ee.node ){
      return (
        navigate(ee.node.fields.slug)
      )
    }
    return null;

  }
  return [
    <div className="max-w-750 py-25 mx-auto text-center">
      <div className="flex justify-center items-center">
        <img src={sample} alt="logo" className="max-w-full"/>
      </div>
      <h1 className="m-0 text-48">How can we help you?</h1>
      <p className="text-center text-16 mb-16 mx-auto">
        Search and browse in depth articles and videos about VPC+.
      </p>

      <Autocomplete
        id="highlights-demo"
        freeSolo
        autoComplete
        filterOptions={filterOptions}
        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.node ? option.node.frontmatter.title : ''}
        onChange={(e, ee)=>handleSub(e, ee)}
        renderInput={(params, op) => (
          <TextField
            {...params}
            classes={{
              root: classes.textFieldRoot,
            }}
            placeholder='Search for answers'
            margin="normal"
            variant="outlined"
            size="medium"
            onClick={(option) => console.log(option)}

            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <IconButton disabled size="small">
                    <SearchIcon className="text-36"/>
                  </IconButton>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  ];
}
export default SearchView;


